#headerCarousel .carousel-item {
   height: 100vh;
   min-height: 350px;
   background: no-repeat center center scroll;
   -webkit-background-size: cover;
   -moz-background-size: cover;
   -o-background-size: cover;
   background-size: cover;
}

#headerCarousel .carousel-caption {
   background-color: rgba(0, 0, 0, 0.6);
   border-radius: 25px;
   min-height: 30vh;
}

#whyChooseUsCheckList {
   font-size: 1.3em;
}

#whyChooseUsCheckList .fa-li {
   color: #28a745;
}

.row-margin-cause-overflow {
   margin-left: 0;
   margin-right: 0;
}

.required-input-label::after {
   content: " *";
   color: #ff0000;
   font-weight: bold;
}