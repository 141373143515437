.container-fluid {
   padding: 0px;
}

/* footer */
.social-media-icon {
   font-size: 2rem;
}

#wrapper-footer {
   background-image: -webkit-gradient(linear, left top, left bottom, from(white), color-stop(rgba(255, 255, 255, .2)), to(rgba(255, 255, 255, .2)));
   background-image: linear-gradient(rgba(255, 255, 255, 0), white);
}

#contactInfoFooter {
   word-break: break-word;
}