@import url('https://fonts.googleapis.com/css?family=Playball&display=swap');

#headerLogo {
   font-family: 'Playball', cursive;
   font-size: 1.5em;
}

#navbarMainContainer .fa-phone {
   color: #28a745;
}

/* navbar */
#navbarLinks {
   padding-left: 0;
   padding-right: 0;
   padding-top: 0;
}

#navbarCollapse {
   padding-top: 0;
   text-align: center;
}

#navbarCollapse .navbar-nav {
   flex-basis: 100%;
   justify-content: center;
}

#navbarCollapse .nav-link {
   padding-top: 0;
   padding-bottom: 0;
}